import React from "react";
import { useRouter } from "next/router";
import { BASE_URL } from "components/settings/api";

const GoogleLoginButton = ({ active = true }) => {
  const router = useRouter();
  const { utm_campaign } = router.query;
  const baseUrl =
    (process.env.NEXT_PUBLIC_BACK_END_URL || BASE_URL) +
    "api/v1/users/auth/google";
  const url = utm_campaign
    ? baseUrl + `/?utm_campaign=${utm_campaign}`
    : baseUrl;

  if (active) {
    return (
      <a href={url}>
        <img
          className="oauthbutton"
          src={`/svg/google-active-login.svg`}
          alt="google button"
          height="48px"
          width="48px"
        />
      </a>
    );
  } else {
    return (
      <img
        src={`/svg/google-inactive-login.svg`}
        alt="google button"
        height="48px"
        width="48px"
      />
    );
  }
};

export default GoogleLoginButton;
