import React from "react";
import { useRouter } from "next/router";
import { BASE_URL } from "components/settings/api";

const FacebookLoginButton = ({ active = true }) => {
  const router = useRouter();
  const { utm_campaign } = router.query;
  const baseUrl =
    (process.env.NEXT_PUBLIC_BACK_END_URL || BASE_URL) +
    "api/v1/users/auth/facebook";
  const url = utm_campaign
    ? baseUrl + `/?utm_campaign=${utm_campaign}`
    : baseUrl;

  if (active) {
    return (
      <a href={url} role="button" aria-label="Login with Facebook">
        <img
          className="oauthbutton"
          src={`/svg/facebook-active-login.svg`}
          alt="facebook button"
          height="48px"
          width="48px"
          aria-disabled="true"
        />
      </a>
    );
  } else {
    return (
      <img
        src={`/svg/facebook-inactive-login.svg`}
        alt="facebook button"
        height="48px"
        width="48px"
      />
    );
  }
};

export default FacebookLoginButton;
